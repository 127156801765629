import Vue from 'vue';
import { Component, Watch, Prop, Inject, Emit } from 'vue-property-decorator';

@Component
export default class Profile extends Vue {
  private languages = [
    { value: 'de', text: 'Deutsch' },
    { value: 'en', text: 'English' },
    { value: 'nl', text: 'Nederlands' }
  ];

  private languageChanged(lang: string) {
    localStorage.language = lang;
    this.$i18n.locale = lang;
    this.$daylocale(lang);
  }

  private selectedLanguage() {
    return this.languages.filter((l: any) => l.value === this.$i18n.locale)[0];
  }
}
