import Vue from 'vue';
import { Component, Inject, Emit } from 'vue-property-decorator';
import { DateFilter } from '@/models/date-filter';

@Component
export default class WeekOverviewHeader extends Vue {
  private filterSelected: DateFilter;

  private dateOptions: { [dateText: string]: DateFilter } = {
    'weekoverviewHeader.filter.next3Months': DateFilter.Next3Monts,
    'weekoverviewHeader.filter.currentAndLast3Months': DateFilter.CurrentAndLast3Months,
    'weekoverviewHeader.filter.next6AndLast3Months': DateFilter.Next6AndLast3Months,
    'weekoverviewHeader.filter.last6Months': DateFilter.Last6Months,
    'weekoverviewHeader.filter.last12Months': DateFilter.Last12Months,
    'weekoverviewHeader.filter.all': DateFilter.All,
  };

  @Emit()
  private dateSelected(filter: DateFilter) {
  }

  @Emit()
  private changeSortOrder() {
  }
}
