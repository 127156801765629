import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import Vue2Filters from 'vue2-filters';
import router from '@/router';
import store from '@/store';

import { Dayjs } from '@/plugins/vue-dayjs/index';
import { GraphRepositoryPlugin } from '@/repositories/graph-repository';
import { CrmRepositoryPlugin } from '@/repositories/crm-repository';
import TimesheetApp from '@/timesheet-app.vue';

import nlResources from '@/resources/labels.nl.json';
import enResources from '@/resources/labels.en.json';
import deResources from '@/resources/labels.de.json';
import msal from './plugins/msal';
import './scss/_main.scss';

Vue.config.productionTip = false;

if (!localStorage.language) {
  localStorage.language = 'en';
}
msal.run(() => {
  if (localStorage.language === undefined || localStorage.language === null) {
    localStorage.language = msal.language;
  }

  Vue.use(Dayjs, {
    locale: localStorage.language,
  });

  Vue.use(GraphRepositoryPlugin, {
    baseUrl: 'https://graph.microsoft.com',
    scopes: ['User.Read'],
  });

  Vue.use(CrmRepositoryPlugin, {
    baseUrl: window.env.BACKENDURI,
    scopes: [...window.env.SCOPES],
  });

  Vue.use(BootstrapVue);
  Vue.use(Vue2Filters);
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale: localStorage.language,
    fallbackLocale: 'nl',
    silentTranslationWarn: true,
    messages: Object.assign({
      nl: nlResources,
      en: enResources,
      de: deResources
    }),
  });

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(TimesheetApp),
  }).$mount('#app');
});
